import { useState } from 'react';
import React from 'react';
import { mec_name } from './funcs.js'
 
import './css/lhd_f.css';
function Lhd_sotr(props) {
    const [show, Setshow] = useState("Показать")
    const cur=props.sel
    const [sgod, Setsgod] = useState(cur.god)

    let w_lhds = []; w_lhds.length = 0
    let w_lscs = []; w_lscs.length = 0
    let w_str = []; w_str.length = 0
    let w_lhd = {}

    for (let i = 0; i < props.lhds.length; i++) {
        if (props.lhds[i].sotr == cur.sotr && props.lhds[i].god == cur.god) {
            w_lhds.push(props.lhds[i])
        }
    }

    for (let i = 0; i < props.lscs.length; i++) {
        let x_lscs = props.lscs[i]
        if (x_lscs.sotr == cur.sotr && x_lscs.god == cur.god)
            w_lscs.push(x_lscs)
    }

    w_lscs.sort(function (a, b) {
        if ((a.god - b.god) > 0 || (a.god === b.god && (a.mec - b.mec) > 0))
            return 1;
        else
            if (a.god === b.god && a.mec === b.mec)
                if ((a.godb - b.godb) > 0 || (a.godb === b.godb && (a.mecb - b.mecb) > 0))
                    return 1;
                else
                    if (a.godb === b.godb && a.mecb === b.mecb)
                        if (a.sq > b.sq)
                            return 1;
                        else
                            return -1;
                    else
                        return -1
            else
                return -1

    })

    let cur_mec = 0
    w_lscs.forEach((lsc) => {
        if (cur_mec !== lsc.mec) {
            cur_mec = lsc.mec
            for (let x = 0; x < w_lhds.length; x++) {
                if (w_lhds[x].mec === cur_mec) {
                    w_str.push({
                        "sq": " "
                        , "kopb": w_lhds[x].kopb
                        , "name": mec_name(w_lhds[x].mec)
                        , "mec": w_lhds[x].mec
                        , "rabt": w_lhds[x].rabt
                        , "oklad": w_lhds[x].oklad
                        , "nach": w_lhds[x].nach
                        , "uder": w_lhds[x].uder
                    })
                    break
                }
            }
        }
        w_str.push({
            "sq": lsc.sq
            , "name": lsc.name
            , "mec": lsc.mec
            , "grs": lsc.grs
            , "nach": lsc.nach
            , "uder": lsc.uder
            , "god": lsc.god, "mec": lsc.mec
            , "godb": lsc.godb, "mecb": lsc.mecb
        })
    })
    let s_hide = ""
    if (show == "Скрыть")
        s_hide = ""
    else
        s_hide = "hid"


    let srows = w_str.map((ar, index) => {

        if (ar.sq === ' ')
            return (
                <tr className="tr_mec" id={"mec" + "_" + ar.mec} onClick={open_mec}>
                    <td className="sumr">{ar.kopb}</td>

                    <td colSpan="2">{ar.name} Время {ar.rabt} Оклад {ar.oklad}</td>
                    <td className="sumr">+{ar.nach}</td>
                    <td className="sumr">-{ar.uder}</td>
                </tr>
            )
        else
            if ((ar.god - ar.godb) < 0 || (ar.god === ar.godb && (ar.mec - ar.mecb) < 0)) {

                return (
                    <tr className={"tr_grs" + ' ' + "mec_" + ar.mec + ' ' + s_hide} >
                        <td> </td>
                        <td>{ar.sq} </td>
                        <td colSpan="1" >{ar.name}
                            <span className="tr_mecb">
                                /за {mec_name(ar.mecb)} {ar.godb}
                            </span>
                        </td>
                        <td className="sumr">{ar.nach}</td>
                        <td className="sumr">{ar.uder}</td>

                    </tr>
                )
            }
            else
                if ((ar.god - ar.godb) > 0 || (ar.god === ar.godb && (ar.mec - ar.mecb) > 0)) {
                    return (
                        <tr className={"tr_grs" + ' ' + "mec_" + ar.mec + ' ' + s_hide} >
                            <td> </td>
                            <td>{ar.sq} </td>
                            <td colSpan="1" >{ar.name}
                                <span className="tr_mecb">
                                    /за {mec_name(ar.mecb)} {ar.godb}
                                </span>
                            </td>
                            <td className="sumr">{ar.nach}</td>
                            <td className="sumr">{ar.uder}</td>

                        </tr>
                    )
                }
                else {
                    return (
                        <tr className={"tr_grs" + ' ' + "mec_" + ar.mec + ' ' + s_hide}
                            id={"grs" + "_" + ar.grs + "@" + "mec" + "_" + ar.mec}>
                            <td> </td>
                            <td>{ar.sq} </td>
                            <td colSpan="1" >{ar.name} </td>
                            <td className="sumr">{ar.nach}</td>
                            <td className="sumr">{ar.uder}</td>

                        </tr>
                    )
                }
    })

    return (
        <div>
            <table id="table_lhd_f">
                <thead>
                    <tr>
                        <th className="th_center ngrs" colSpan="5">
                            {cur.sotr + ' ' + cur.fio + ' ' + cur.nmag + ' ' + cur.dolg}
                        </th>
                    </tr>
                    <tr>
                        <th className="th_center tr_lsc_mec" colSpan="5">
                            <button onClick={prior_lhd}> Назад </button>
                            <span > {cur.god}  </span>
                            <button onClick={next_lhd}> Вперед</button>
                            <span>.......<button id="grs_show" onClick={grs_hid}> {show}</button></span>
                        </th>
                    </tr>
                    <tr>
                        <th > Сальдо</th>
                        <th > Код</th>
                        <th > Операция</th>
                        <th className="sumr"> Начисл.</th>
                        <th className="sumr"> Удержано</th>



                    </tr>
                </thead>
                <tbody>
                    {srows}
                </tbody>
            </table>


        </div>

    )

    function grs_hid(e) {

        document.querySelectorAll('.tr_grs').forEach((el) => {
            if (el.classList.contains('hid')) {
                el.classList.remove('hid')
            } else {
                el.classList.add('hid');
            }

        })

        if (show === 'Показать') {
            Setshow('Скрыть')

        }
        else
            Setshow('Показать')



    }

    function prior_lhd() {
        cur.god = cur.god - 1
        Setsgod(cur.god)
        // if ("sotr" in props)
        //     Setsgod(wgod)
        // else {
        //     w_lhd = { "sotr": s_lhd.sotr, "god": wgod }
        //     props.Setslhd(w_lhd)
        // }
    }
    function next_lhd() {
        let wgod = cur.god
        cur.god++
        Setsgod(cur.god)
        // if ("sotr" in props)
        //     Setsgod(wgod)
        // else {
        //     w_lhd = { "sotr": s_lhd.sotr, "god": wgod }
        //     props.Setslhd(w_lhd)
        // }
    }

    function open_mec(e) {
        const pmec = e.currentTarget.id
        document.querySelectorAll('.tr_grs' + '.' + pmec).forEach((el) => {
            if (el.classList.contains('hid')) {
                el.classList.remove('hid')
            } else {
                el.classList.add('hid');
            }

        })

    }
}



export default Lhd_sotr;
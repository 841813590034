import grss  from './data/grs.json';
import sotrs from './data/sotr.json';
import lhds from './data/lhd.json';
import React from 'react'

let q_nmag=[];q_nmag.length=0
lhds.forEach(lhd=>{
    if (q_nmag.indexOf(lhd.nmag)==-1)
        q_nmag.push(lhd.nmag)
})

grss.sort(function (a, b) {
    if (a.sq > b.sq) return 1; else return -1;
})
sotrs.sort(function (a, b) {
    if (a.fio > b.fio) return 1; else return -1;
})
export function List_grs(props) {
       let s_grss = grss.map(grs => {
            return  <option key={grs.kod} value={grs.kod}>{grs.sq + ' ' + grs.name}</option>
    })
    
    return (
            <div >
                <select id="list_grs" onChange={(e) => {props.Sets_grs(e.target.value)}}>
                
                <option key="0" value="0" >Операции</option>
                    {s_grss}
                </select>
            </div>
    )

}
export function List_nmag(props) {
    
    let x_nmag = q_nmag.map((ar) => {
        return (
            <option key={ar} value={ar}>{ar}</option>
        )
    })
    
    return (
                <div  >
                    <select id="list_nmag" onChange={(e) => props.Set_nmag(e.target.value)}>
                    <option key={" "} value={" "}>Отделы </option>
                    {x_nmag}
                    </select>
                </div>
    )

}
export function List_sotr(props) {
    
    let x_sotrs = sotrs.map((ar) => {
        return (
            <option key={ar.kod} value={ar.kod}>{ar.fio}</option>
        )
    })
    
    return (
                <div  >
                    <select id="list_sotr" onChange={(e) => props.Sets_sotr(e.target.value)}>
                    <option key={"0"} value={"0"}>Сотрудники</option>
                        {x_sotrs}
                    </select>
                </div>
    )

}

 
import React from 'react'
import { useState } from 'react';
import { List_sotr, List_grs ,List_nmag} from './comps.js';
import { lhd_grss } from './funcs.js';
import Lhd_sotr from './Lhd_sotr.js';
import Lhd_nmag from './Lhd_nmag.js';
import { Context } from './Context.js';
import './css/lhds_t.css';
let sfio = " "; let sdolg = " "; let sgod = "2024"; let smec = "0"; let snmag = " "; let stab = " "
function Lhds_t(props) {

  const [slhd, Setslhd] = useState({ "god": "2024", "mec": "0", "fio": " ", "dolg": " ", "nmag": " ", "tab": " " })
  const [nsort, Setnsort] = useState(1)
  const [sel, Setsel] = useState({ "sotr": "0", "god": "0", "mec": "0", "nmag": " " })
  const [s_grs, Sets_grs] = useState(0)
  const [s_sotr, Sets_sotr] = useState(0)
  //const [s_nmag, Sets_nmag] = useState("")

  let cnt = 0
  let w_sotr;
  let m_ar = []; m_ar.length = 0

  let w_lhds = props.lhds.filter(function (ar) {
    if (slhd.dolg > ' ' && ar.dolg.indexOf(slhd.dolg) == -1)
      return false
    if (slhd.fio > " " && ar.fio.indexOf(slhd.fio) == -1)
      return false
    if (slhd.nmag > " " && ar.nmag.indexOf(slhd.nmag) == -1)
      return false
    if (slhd.god > 0 && slhd.god !== ar.god)
      return false
    if (slhd.mec > 0 && slhd.mec !== ar.mec)
      return false
    if (slhd.tab > 0 && slhd.tab !== ar.sotr)
      return false
    if (props.sotr > 0 && props.sotr !== ar.sotr)
      return false
    if (s_sotr > 0 && s_sotr !== ar.sotr)
      return false
    if (s_grs > 0 && lhd_grss(ar, props.lscs).indexOf(s_grs) == -1)
      return false
    if (!(m_ar.includes(ar.nmag)))
      m_ar.push(ar.nmag)
    return true
  })

  m_ar.forEach(nmag => {
    w_lhds.push({ "sotr": "", "nmag": nmag, "god": slhd.god, "mec": slhd.mec })
  })
  sort_w_lhds()

  let srows = w_lhds.map((ar) => {
    //alert(ar.sotr)

    if (ar.sotr > 0 && (ar.d_otp || ar.s_otp))
      return (
        <tr id={ar.sotr + '@' + ar.god + '@' + ar.mec + '@' + ar.nmag} className="tr_lhds tr_otp" onClick={init_lhd}>
          <td className="tab">{ar.sotr}  </td>
          <td className="fio">{ar.fio}</td>
          <td className="dolg">{ar.dolg}  </td>
          <td className="nmag">{ar.nmag}  </td>

          <td className="god">{ar.god}</td>
          <td className="mec">{ar.mec}</td>
        </tr>
      )
    else
      return (
        <tr id={ar.sotr + '@' + ar.god + '@' + ar.mec + '@' + ar.nmag} className="tr_lhds" onClick={init_lhd}>
          <td className="tab">{ar.sotr}  </td>
          <td className="fio">{ar.fio}</td>
          <td className="dolg">{ar.dolg}  </td>
          <td className="nmag">{ar.nmag}  </td>

          <td className="god">{ar.god}</td>
          <td className="mec">{ar.mec}</td>
        </tr>
      )
  })

  let w_lhd_f = ""

  if (sel.sotr)
    w_lhd_f = <Lhd_sotr sel={sel} lhds={props.lhds} lscs={props.lscs} />
  else
    w_lhd_f = <Lhd_nmag sel={sel} lhds={props.lhds} lscs={props.lscs} />



  return (
    <div style={{ "display": "flex" }}>

      <div id="div_lhds_t" >

        <table id="table_lhds_t" >
          <thead>
            <tr >
              <th colSpan="6">Табель
                <div style={{ "display": "flex" }}>
                  <List_sotr Sets_sotr={Sets_sotr} />
                  
                  <List_nmag Set_nmag={Set_nmag} />
                  <List_grs Sets_grs={Sets_grs} />
                </div>
              </th>
            </tr>
            <tr >
              <th className="tab" onClick={() => Setnsort(1)}>
                Таб №  <input id="tab" value={stab} onChange={e => set_state(e)} /> </th>
              <th onClick={() => Setnsort(2)}>
                Фамилия И.О <input id="fio" value={sfio} onChange={e => set_state(e)} />
              </th>
              <th onClick={() => Setnsort(3)} >
                Должность <input id="dolg" value={sdolg} onChange={e => set_state(e)} />
              </th>
              <th onClick={() => Setnsort(4)} >
                Подр <input id="nmag" value={snmag}
                  // onChange={e => set_state(e)}
                  // onClick={e => set_sel(e)}
                />
              </th>

              <th onClick={() => Setnsort(6)}>
                Год  <input id="god" value={sgod} onChange={e => set_state(e)} />
              </th>
              <th onClick={() => Setnsort(7)}>
                Мес <input id="mec" value={smec} onChange={e => set_state(e)} />
              </th>
            </tr>
          </thead>
          <tbody >
            {srows}
          </tbody>
        </table>
      </div >
      <div className="lhd_f" id="div_lhd_f_god" >
       
          {w_lhd_f}
         
      </div>
    </div>
  )

  function sort_w_lhds() {
    if (nsort) {
      w_lhds.sort(function (a, b) {
        if (nsort === 1)
          if ((a.sotr - b.sotr) > 0) return 1; else return -1;
        else
          if (nsort === 2)
            if (a.fio > b.fio) return 1; else return -1;
          else
            if (nsort === 3)
              if (a.dolg > b.dolg) return 1; else return -1;
            else
              if (nsort === 4)
                if (a.nmag > b.nmag) return 1; else return -1;
              else
                if (nsort === 5 || nsort === 6)
                  if ((a.god - b.god) > 0 || ((a.god === b.god) && (a.mec - b.mec) > 0))
                    return 1
                  else
                    return -1


      });
    }
  }
  function Set_nmag(v) {
      snmag = v
      Setslhd({ "fio": sfio, "god": sgod, "mec": smec, "dolg": sdolg, "nmag": snmag, "tab": stab })
    
  }
  function set_state(e) {
    let pid = e.currentTarget.id
    let pvalue = e.currentTarget.value
    if (pid == "fio")
      sfio = pvalue
    else
      if (pid == "nmag")
        snmag = pvalue
      else
        if (pid == "dolg")
          sdolg = pvalue
        else
          if (pid == "god")
            sgod = pvalue
          else
            if (pid == "mec")
              smec = pvalue
            else
              if (pid == "tab")
                stab = pvalue

    Setslhd({ "fio": sfio, "god": sgod, "mec": smec, "dolg": sdolg, "nmag": snmag, "tab": stab })
  }
  function init_lhd(e) {
    const pid = e.currentTarget.id
    const psotr = pid.split("@")[0]   //sotr
    const pgod = pid.split("@")[1]    //god
    const pmec = pid.split("@")[2]    //mec
    const pnmag = pid.split("@")[3]    //mag

    if (psotr && psotr == slhd.sotr)
      return
    if (psotr) {
      for (let i = 0; i < w_lhds.length; i++)
        if (w_lhds[i].sotr == psotr && w_lhds[i].god == pgod && w_lhds[i].mec == pmec) {

          Setsel(w_lhds[i])
          break
        }
    }
    else
      for (let i = 0; i < w_lhds.length; i++)
        if (w_lhds[i].nmag == pnmag && w_lhds[i].god == pgod && w_lhds[i].mec == pmec) {
          Setsel(w_lhds[i])
          break
        }

  }

}
export default Lhds_t;
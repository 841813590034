import { useState } from 'react';
import React from 'react';
import {mec_name} from './funcs.js'
 

import './css/lhd_f.css';
function Lhd_nmag(props) {
    const cur=props.sel
    const [show, Setshow] = useState("Показать")
    const [sgod, Setsgod] = useState(cur.god)
    let w_lhds = []; w_lhds.length = 0
    let w_lscs = []; w_lscs.length = 0
    let w_str = []; w_str.length = 0
    let w_lhd = {}

    
    w_str.length = 0; let q_mec = []
    for (let ix = 0; ix < props.lhds.length; ix++) {
        let x_lhd = props.lhds[ix]

        if (x_lhd.sotr > 0 && x_lhd.nmag == cur.nmag && x_lhd.god == cur.god) {
            if (!q_mec.includes(x_lhd.mec)) {
                q_mec.push(x_lhd.mec)
                w_str.push({
                    "sq": " "
                    , "mec": x_lhd.mec
                    , "nmag": x_lhd.nmag
                    , "name": mec_name(x_lhd.mec)
                    , "nach": "", "uder": "", "sotr": 0
                })
            }
            for (let zz = 0; zz < props.lscs.length; zz++) {
                let x_lsc = props.lscs[zz]
                if (x_lsc.sotr == x_lhd.sotr && x_lsc.god == x_lhd.god && x_lsc.mec == x_lhd.mec && !(x_lsc.sumr == 0)) {
                    if (!q_mec.includes(x_lsc.grs + ' ' + x_lsc.mec)) {
                        q_mec.push(x_lsc.grs + ' ' + x_lsc.mec)
                        w_str.push({
                            "mec": x_lsc.mec
                            , "sq": x_lsc.sq
                            , "grs": x_lsc.grs
                            , "name": x_lsc.ngrs
                            , "nmag": x_lhd.nmag
                            , "nach": "", "uder": "", "sotr": 0
                        })
                    }
                    w_str.push({
                        "mec": x_lsc.mec
                        , "sq": x_lsc.sq + '/' + x_lsc.sotr
                        , "grs": x_lsc.grs
                        , "nach": x_lsc.nach
                        , "uder": x_lsc.uder
                        , "nmag": x_lhd.nmag
                        , "name": x_lhd.fio
                        , "sotr": x_lhd.sotr
                    })
                    for (let k = 0; k < w_str.length; k++)
                        if (w_str[k].sotr == 0 && w_str[k].mec == x_lsc.mec && (w_str[k].sq == ' ' || w_str[k].grs == x_lsc.grs)) {
                            if ('nach' in x_lsc) {
                                w_str[k].nach = Number(w_str[k].nach) + Number(x_lsc.nach)
                                w_str[k].nach = +w_str[k].nach.toFixed(2)
                            }
                            if ('uder' in x_lsc) {
                                w_str[k].uder = Number(w_str[k].uder) + Number(x_lsc.uder)
                                w_str[k].uder = +w_str[k].uder.toFixed(2)
                            }
                        }
                }

            }
            //break
        }
    }
    w_str.sort(function (a, b) {
        if ((a.mec - b.mec) > 0)
            return 1
        else
            if ((a.mec - b.mec) == 0)
                if (a.sq > b.sq)
                    return 1
                else
                    return -1
            else
                return -1
    })

    //alert(w_str.length)

    let srows = w_str.map((ar, index) => {

        if (ar.sq === ' ')
            return (
                <tr className="tr_mec" id={"mec" + "_" + ar.mec} onClick={open_mec}>
                    <td>{ar.sq}</td>
                    <td colspan="1">{ar.name}</td>
                    <td className="sumr">+{ar.nach}</td>
                    <td className="sumr">-{ar.uder}</td>
                </tr>
            )
        else
            if (ar.sotr)
                return (
                    <tr className={"tr_sotr" + " " + "grs_" + ar.grs + " " + "mec_" + ar.mec + ' ' + "hid"}>
                        <td>{"_" + ar.sq.split("/")[1]}</td>
                        <td colspan="1">{ar.name}</td>
                        <td className="sumr">+{ar.nach}</td>
                        <td className="sumr">-{ar.uder}</td>
                    </tr>
                )
            else {
                return (
                    <tr className={"tr_grs" + ' ' + "mec_" + ar.mec + ' ' + "hid"}
                        id={"grs" + "_" + ar.grs + "@" + "mec" + "_" + ar.mec}
                        onClick={open_grs}>
                        <td >{ar.sq}</td>
                        <td >{ar.name}</td>
                        <td className="sumr">{ar.nach}</td>
                        <td className="sumr">{ar.uder}</td>

                    </tr>
                )
            }
    })

    return (
        <div>
            <table id="table_lhd_f">
                <thead>

                    <tr>
                        <th className="th_center tr_lsc_mec" colSpan="4">
                            <button onClick={prior_lhd}> Назад </button>
                            <span > {cur.god}  </span>
                            <button onClick={next_lhd}> Вперед</button>
                            <span>.......<button id="grs_show" onClick={grs_hid}> Показать</button></span>
                        </th>



                    </tr>
                    <tr>
                        <th className="sq_grs"> Код</th>
                        <th className="th_center ngrs" colSpan="8">
                            {cur.nmag}
                        </th>


                    </tr>
                </thead>
                <tbody>
                    {srows}
                </tbody>
            </table>


        </div>

    )
    function grs_hid(e) {
        document.querySelectorAll('.tr_grs').forEach((el) => {
            if (el.classList.contains('hid')) {
                el.classList.remove('hid')
            } else {
                el.classList.add('hid');
            }
        })
        let elem = document.querySelector('#grs_show');
        if (elem.textContent == 'Показать')
            elem.textContent = 'Скрыть'
        else
            elem.textContent = 'Показать'
         
    }


    function prior_lhd() {
        let wgod = cur.god - 1
        w_lhd = { "god": wgod, "nmag": cur.nmag }
        props.Setslhd(w_lhd)
    }
    function next_lhd() {
        let wgod = cur.god
        wgod++
        w_lhd = { "god": wgod, "nmag": cur.nmag }
        props.Setslhd(w_lhd)
    }
    function open_grs(e) {
        const pid = e.currentTarget.id
        const pgrs = pid.split("@")[0]
        const pmec = pid.split("@")[1]
        // alert('.tr_sotr' + '.'+pgrs+'.'+pmec)
        document.querySelectorAll('.tr_sotr' + '.' + pgrs + '.' + pmec).forEach((el) => {
            if (el.classList.contains('hid')) {
                el.classList.remove('hid')
            } else {
                el.classList.add('hid');
            }

        })

    }
    function open_mec(e) {
        const pmec = e.currentTarget.id
        document.querySelectorAll('.tr_grs' + '.' + pmec).forEach((el) => {
            if (el.classList.contains('hid')) {
                el.classList.remove('hid')
            } else {
                el.classList.add('hid');
            }

        })

    }

}


export default Lhd_nmag;